<template>
  <div class="auth-wrapper auth-v2 login">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo" />
      <!-- /Brand logo-->

      <!-- Login-->
      <div class="cg_login_wrapper">
        <h3>Login</h3>
        <b-alert
          v-if="message"
          variant="danger"
          show
        >
          <div class="alert-body font-small-2">
            <p class="text-center">
              <small class="mr-50">
                <span class="font-weight-bold text-danger">{{ message }}</span>
              </small>
            </p>
          </div>
        </b-alert>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{ invalid }"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="login"
          >
            <!-- username -->
            <b-form-group
              :label="$t('field.username')"
              label-for="login-username"
            >
              <validation-provider
                #default="{ errors }"
                vid="username"
                :name="$t('field.username')"
                rules="required"
              >
                <b-form-input
                  id="login-username"
                  v-model="data.username"
                  :state="errors.length > 0 ? false : null"
                  name="login-username"
                  placeholder="username"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- forgot password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="login-password">{{ $t("field.password") }}</label>
              </div>
              <validation-provider
                #default="{ errors }"
                vid="password"
                :name="$t('field.password')"
                rules="required"
              >
                <n-password-input
                  id="password"
                  v-model="data.password"
                  name="password"
                  placeholder="············"
                  :errors="errors"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit buttons -->

            <n-button-loading
              type="submit"
              variant="primary"
              :loading="loading"
              :disabled="invalid"
              block
            >
              {{ $t("general.login") }}
            </n-button-loading>
          </b-form>
        </validation-observer>
      </div>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BRow,
  BLink,
  BFormGroup,
  BFormInput,
  BForm,
  BAlert,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import NButtonLoading from '@/components/NButtonLoading.vue'
import NPasswordInput from '@/components/NPasswordInput.vue'
import { initialAbility } from '@/libs/acl/config'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BRow,
    BLink,
    BFormGroup,
    BFormInput,
    BForm,
    BAlert,
    NButtonLoading,
    NPasswordInput,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      data: {
        username: null,
        password: null,
      },
      message: null,
      loading: false,
      sideImg: require('@/assets/images/pages/M99-Ring-Fight-4.jpg'),
    }
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.loading = true
          useJwt
            .login({
              ...this.data,
            })
            .then(response => {
              const { data } = response.data
              const userData = data.user
              userData.ability.forEach(x => { x.subject = x.subject.toLowerCase() })
              useJwt.setToken(data.accessToken)
              useJwt.setRefreshToken(data.refreshToken)
              localStorage.setItem('userData', JSON.stringify(userData))
              this.$store.commit('profile/UPDATE_PROFILE', userData)

              let newAbility = [...initialAbility]
              if (userData.ability) {
                if (userData.isReadonly) {
                  userData.ability.forEach(x => {
                    x.action = 'read'
                    return x
                  })
                }

                newAbility = [...userData.ability, ...initialAbility]
              }
              this.$ability.update(newAbility)

              this.$router
                .replace(getHomeRouteForLoggedInUser('admin'))
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Welcome ${userData.name || userData.username}`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: 'You have successfully logged!',
                    },
                  })
                })
            })
            .catch(error => {
              this.loading = false
              if (error.response?.status === 422) {
                this.$refs.loginForm.setErrors(error.response.data.message)
              }
            })
        }
      })
    },
  },
  setup() {
    // App Name
    const { appName } = $themeConfig.app
    return {
      appName,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
