<template>
  <b-tabs
    v-model="tabIndex"
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <!-- general tab -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t("updateProfile.title") }}</span>
      </template>

      <account-setting-profile v-if="tabIndex === 0" />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t("changePassword.title") }}</span>
      </template>

      <account-setting-password v-if="tabIndex === 1" />
    </b-tab>
    <!--/ change password tab -->

    <!-- currency tab -->
<!--    <b-tab v-if="typeId === 1 || typeId === 8">-->
<!--      &lt;!&ndash; title &ndash;&gt;-->
<!--      <template #title>-->
<!--        <feather-icon-->
<!--          icon="DollarSignIcon"-->
<!--          size="18"-->
<!--          class="mr-50"-->
<!--        />-->
<!--        <span class="font-weight-bold">{{-->
<!--          $t("general.updateExchangeRate")-->
<!--        }}</span>-->
<!--      </template>-->

<!--      <currency-setting v-if="tabIndex === 2" />-->
<!--    </b-tab>-->
    <!--/ currency tab -->

    <!-- change deposit setting tab -->
    <b-tab v-if="isCashMarket">
      <!-- title -->
      <template #title>
        <feather-icon
          icon="CreditCardIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{
          $t("breadcrumb.depositSetting")
        }}</span>
      </template>

      <deposit-setting v-if="tabIndex === (typeId === 1 || typeId === 8) ? 3 : 2" />
    </b-tab>
    <!--/ change deposit setting tab -->
  </b-tabs>
</template>

<script>
import { BTab, BTabs } from 'bootstrap-vue'
import AccountSettingProfile from './AccountSettingProfile.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'
import CurrencySetting from './CurrencySetting.vue'
import DepositSetting from './DepositSetting.vue'

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingProfile,
    AccountSettingPassword,
    CurrencySetting,
    DepositSetting,
  },
  data() {
    return {
      tabIndex: this.$route.query.index ? Number(this.$route.query.index) : 0,
      options: {},
    }
  },
  computed: {
    typeId() {
      if (this.$store.state.profile.typeId) {
        return this.$store.state.profile.typeId
      }
      const userData = JSON.parse(localStorage.getItem('userData'))
      return userData?.typeId
    },
    isCashMarket() {
      if (this.$store.state.profile.isCashMarket) {
        return this.$store.state.profile.isCashMarket
      }
      const userData = JSON.parse(localStorage.getItem('userData'))
      return userData?.isCashMarket
    },
  },
  watch: {
    tabIndex(val) {
      this.$router.replace({
        query: {
          index: val,
        },
      })
    },
  },
  mounted() {},
}
</script>
